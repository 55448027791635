const PerformancePage = () =>
  import(
    /* webpackChunkName: "PerformancePage" */
    '@/views/Performance/PerformancePage.vue'
  )
const OKRsPage = () =>
  import(
    /* webpackChunkName: "OKRsPage" */
    '@/views/Performance/Tabs/OKRs/OKRsPage.vue'
  )
const KPIsPage = () =>
  import(
    /* webpackChunkName: "KPIsPage" */
    '@/views/Performance/Tabs/KPIs/KPIsPage.vue'
  )
const GoalsPage = () =>
  import(
    /* webpackChunkName: "GoalsPage" */
    '@/views/Performance/Tabs/Goals/GoalsPage.vue'
  )
const TreeViewPage = () =>
  import(
    /* webpackChunkName: "OKRsPage" */
    '@/views/Performance/Tabs/OKRs/Tabs/TreeView/TreeViewPage.vue'
  )
const KPIsTreeViewPage = () =>
  import(
    /* webpackChunkName: "KPIsPage" */
    '@/views/Performance/Tabs/KPIs/Tabs/KPIsTreeViewPage/KPIsTreeViewPage.vue'
  )
const ListViewPage = () =>
  import(
    /* webpackChunkName: "OKRsPage" */
    '@/views/Performance/Tabs/OKRs/Tabs/ListView/ListViewPage.vue'
  )

const KPIsListViewPage = () =>
  import(
    /* webpackChunkName: "OKRsPage" */
    '@/views/Performance/Tabs/KPIs/Tabs/ListView/ListViewPage.vue'
  )
const InitiativesPage = () =>
  import(
    /* webpackChunkName: "InitiativesPage" */
    '@/views/Performance/Tabs/Initiatives/InitiativesPage.vue'
  )
const InitiativesAllPage = () =>
  import(
    /* webpackChunkName: "InitiativesPage" */
    '@/views/Performance/Tabs/Initiatives/Tabs/All/AllPage.vue'
  )
const InitiativesCoresponsibilityRequestsPage = () =>
  import(
    /* webpackChunkName: "InitiativesPage" */
    '@/views/Performance/Tabs/Initiatives/Tabs/CoresponsibilityRequests/CoresponsibilityRequestsPage.vue'
  )

const DashboardPage = () =>
  import(
    /* webpackChunkName: "DashboardPage" */
    '@/views/Performance/Tabs/Dashboard/DashboardPage.vue'
  )
const OverviewPage = () =>
  import(
    /* webpackChunkName: "DashboardPage" */
    '@/views/Performance/Tabs/Dashboard/Tabs/Overview/OverviewPage.vue'
  )
const DashboardManagementReportPage = () =>
  import(
    /* webpackChunkName: "DashboardPage" */
    '@/views/Performance/Tabs/Dashboard/Tabs/ManagementReport/ManagementReportPage.vue'
  )

const NotificationPage = () =>
  import(
    /* webpackChunkName: "NotificationPage" */
    '@/views/Notification/NotificationPage.vue'
  )
const NotificationSendingCenterPage = () =>
  import(
    /* webpackChunkName: "NotificationPage" */
    '@/views/Notification/Tabs/SendingCenter/SendingCenterPage.vue'
  )
const NotificationListPage = () =>
  import(
    /* webpackChunkName: "NotificationPage" */
    '@/views/Notification/Tabs/Notification/NotificationListPage.vue'
  )

const GoalsDashboards = () =>
  import(
    /* webpackChunkName: "GoalsPage" */
    '@/views/Performance/Tabs/Goals/Tabs/Dashboards/GoalsDashboards.vue'
  )
const MyGoalDashboard = () =>
  import(
    /* webpackChunkName: "GoalsPage" */
    '@/views/Performance/Tabs/Goals/Tabs/Dashboards/GoalsDashboards.vue'
  )

const ComparisonDashboard = () =>
  import(
    /* webpackChunkName: "GoalsPage" */
    '@/views/Performance/Tabs/Goals/Tabs/ComparisonDashboard/ComparisonDashboard.vue'
  )

const ExporterPage = () =>
  import(
    /* webpackChunkName: "ExporterPage" */
    '@/views/Performance/Views/Exporter/Exporter.vue'
  )

import * as _permissions from '@/helpers/ability/permissions'

export const moduleRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login/Login.vue'),
  },
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'PerformancePage' },
  },
  {
    path: '*',
    name: 'NotFound',
    redirect: { name: 'PerformancePage' },
  },

  {
    path: '/notification',
    name: 'Notification',
    component: NotificationPage,
    redirect: { name: 'NotificationList' },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'notification-list',
        name: 'NotificationList',
        component: NotificationListPage,
        meta: {
          title: 'Routes.notification',
        },

        children: [
          {
            path: 'entity/:selectedNodeId',
            name: 'NotificationListWithEntityId',
            component: NotificationListPage,
          },
        ],
      },
      {
        path: 'sending-center',
        name: 'SendingCenter',
        component: NotificationSendingCenterPage,
        meta: {
          title: 'Routes.sendingCenter',
        },

        children: [
          {
            path: 'notification/:selectedNotificationId',
            name: 'SendingCenterWithNotificationId',
            component: NotificationSendingCenterPage,
          },
        ],
      },
    ],
  },

  {
    path: '/exporter/:code',
    name: 'ExporterPage',
    component: ExporterPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'PerformancePage',
    component: PerformancePage,
    meta: {
      requiresAuth: true,
      requiresPermission: _permissions.performance,
    },
    redirect: { name: 'OKRsPage' },
    children: [
      {
        path: 'okrs',
        name: 'OKRsPage',
        component: OKRsPage,
        redirect: { name: 'TreeViewPage' },
        meta: {
          title: 'Routes.okrs',
        },

        children: [
          {
            path: 'listview',
            name: 'ListViewPage',
            component: ListViewPage,
            meta: {
              title: 'Routes.okrs-subroutes.listview',
            },

            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'ListViewPageWithEntityId',
                component: ListViewPage,
              },
            ],
          },
          {
            path: 'treeview',
            name: 'TreeViewPage',
            component: TreeViewPage,
            meta: {
              title: 'Routes.okrs-subroutes.treeview',
            },

            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'TreeViewPageWithEntityId',
                component: TreeViewPage,
              },
            ],
          },
        ],
      },
      {
        path: 'kpis',
        name: 'KPIsPage',
        component: KPIsPage,
        redirect: { name: 'KPIsListViewPage' },
        meta: {
          title: 'Routes.kpis',
        },

        children: [
          {
            path: 'listview',
            name: 'KPIsListViewPage',
            component: KPIsListViewPage,
            meta: {
              title: 'Routes.okrs-subroutes.listview',
            },
            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'KPIsListViewPageWithEntityId',
                component: KPIsListViewPage,
              },
            ],
          },
          {
            path: 'treeview',
            name: 'KPIsTreeViewPage',
            component: KPIsTreeViewPage,
            meta: {
              title: 'Routes.kpis-subroutes.treeview',
            },
            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'KPIsTreeViewPageWithEntityId',
                component: KPIsTreeViewPage,
              },
            ],
          },
        ],
      },
      {
        path: 'goals',
        name: 'GoalsPage',
        component: GoalsPage,
        redirect: { name: 'GoalsListViewPage' },
        meta: {
          title: 'Routes.goals',
        },

        children: [
          {
            path: 'overview',
            name: 'GoalsDashboards',
            component: GoalsDashboards,
            meta: {
              title: 'Routes.goals-subroutes.dashboards',
            },
            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'GoalsDashboardsWithEntityId',
                component: GoalsDashboards,
              },
            ],
          },
          {
            path: 'me',
            name: 'MyGoalDashboard',
            component: MyGoalDashboard,
            meta: {
              title: 'Routes.goals-subroutes.my-dashboard',
            },
            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'MyGoalDashboardWithEntityId',
                component: MyGoalDashboard,
              },
            ],
          },
          {
            path: 'comparison',
            name: 'ComparisonDashboard',
            component: ComparisonDashboard,
            meta: {
              title: 'Routes.goals-subroutes.comparison-dashboard',
            },
            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'ComparisonDashboardWithEntityId',
                component: ComparisonDashboard,
              },
            ],
          },
        ],
      },
      {
        path: 'initiatives',
        name: 'InitiativesPage',
        component: InitiativesPage,
        redirect: { name: 'AllPage' },
        meta: {
          title: 'Routes.initiatives',
        },

        children: [
          {
            path: 'all',
            name: 'AllPage',
            component: InitiativesAllPage,
            meta: {
              title: 'Routes.initiatives-subroutes.all',
            },

            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'AllPageWithEntityId',
                component: InitiativesAllPage,
              },
            ],
          },
          {
            path: 'coresponsibility-requests',
            name: 'CoresponsibilityRequestsPage',
            component: InitiativesCoresponsibilityRequestsPage,
            meta: {
              title: 'Routes.initiatives-subroutes.coresponsibility-requests',
            },

            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'CoresponsibilityRequestsPageWithEntityId',
                component: InitiativesCoresponsibilityRequestsPage,
              },
            ],
          },
        ],
      },
      {
        path: 'dashboard',
        name: 'DashboardPage',
        component: DashboardPage,
        redirect: { name: 'OverviewPage' },
        meta: {
          title: 'Routes.dashboard',
          requiresPermission: _permissions.performance_dashboards,
        },

        children: [
          {
            path: 'overview',
            name: 'OverviewPage',
            component: OverviewPage,
            meta: {
              title: 'Routes.dashboard-subroutes.all',
              requiresPermission: _permissions.performance_dashboards_overview,
            },

            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'OverviewPageWithEntityId',
                meta: {
                  requiresPermission:
                    _permissions.performance_dashboards_overview,
                },
                component: OverviewPage,
              },
            ],
          },
          {
            path: 'management-report',
            name: 'ManagementReportPage',
            component: DashboardManagementReportPage,
            meta: {
              title: 'Routes.dashboard-subroutes.management-report',
              requiresPermission:
                _permissions.performance_dashboards_managementReports,
            },

            children: [
              {
                path: 'entity/:selectedNodeId',
                name: 'ManagementReportPageWithEntityId',
                component: DashboardManagementReportPage,
              },
            ],
          },
        ],
      },
    ],
  },
]
