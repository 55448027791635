<template>
  <div>
    <div
      v-if="$store.getters.isBlurActive"
      class="custom-blur-overlay"
      @click="handleOverlayClick"
    ></div>
    <v-overlay
      :value="$store.getters.isBlurActive"
      class="global-overlay"
      absolute
      @click="handleOverlayClick"
    ></v-overlay>
  </div>
</template>

<script>
export default {
  methods: {
    handleOverlayClick() {
      this.$store.dispatch('setBlurActive', false)
      this.$root.$emit('closeAllModals')
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
